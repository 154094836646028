import './styles/globals.css';
import {
  connect,
  IntentCtx,
  RenderFieldExtensionCtx,
} from 'datocms-plugin-sdk';
import { render } from './utils/render';
import ConfigScreen from './entrypoints/ConfigScreen';
import FinalVideo from './entrypoints/FinalVideo';
import EditorEntry from './entrypoints/EditorEntry';
import 'datocms-react-ui/styles.css';
import UpdateClipSource from './entrypoints/UpdateClipSource';
import RestartTranscript from './entrypoints/RestartTranscript';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APPLICATION_ID || '08ceeee3-1124-402d-882d-15183c7b30d8',
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN || 'pub2b82d906e43cabeb4057b9e6642b688d',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'arbor-video',
    env: process.env.DATO_ENV || process.env.REACT_APP_DATO_ENV || 'dev',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

const isInIframe = window.location !== window.parent.location;

if (isInIframe) {
  connect({
    renderConfigScreen(ctx) {
      return render(<ConfigScreen ctx={ctx} />);
    },
    manualFieldExtensions(ctx: IntentCtx) {
      return [
        {
          id: 'videoEditor',
          name: 'Video Editor',
          type: 'addon',
          fieldTypes: ['link'],
        },
        {
          id: 'updateClipSource',
          name: 'Update Clip Source',
          type: 'addon',
          fieldTypes: ['file'],
        },
        {
          id: 'restartTranscriptGeneration',
          name: 'Restart Transcript Generation',
          type: 'addon',
          fieldTypes: ['link'],
        },
      ];
    },
    renderFieldExtension(
      fieldExtensionId: string,
      ctx: RenderFieldExtensionCtx,
    ) {
      switch (fieldExtensionId) {
        case 'videoEditor':
          return render(
            <>
              <FinalVideo ctx={ctx} />
            </>,
          );
        case 'updateClipSource':
          return render(
            <>
              <UpdateClipSource ctx={ctx} />
            </>,
          );
        case 'restartTranscriptGeneration':
          return render(
            <>
              <RestartTranscript ctx={ctx} />
            </>,
          );
      }
    },
  });
} else {
  render(<EditorEntry />);
}
