import { TranscriptElement, TranscriptChange } from '../../types.ts/transcript';
import ChangeHandler from './ChangeHandler';

class PhotoHighlightChangeHandler extends ChangeHandler {
  apply(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'photo_highlight' },
  ) {
    const elementsCount = change.count || 1;
    for (let i = 0; i < elementsCount; i++) {
      transcriptionElementsMutable[change.index + i].photo_highlight_id =
        change.newPhotoHighlightId;
    }
    transcriptionElementsMutable[
      change.index + elementsCount - 1
    ].last_photo_highlight = change.newPhotoHighlightId ? true : undefined;
  }
}

export default PhotoHighlightChangeHandler;
