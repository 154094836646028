import { TranscriptChange, TranscriptElement } from '../../types.ts/transcript';
import ChangeHandler from './ChangeHandler';

class ShiftChangeHandler extends ChangeHandler {
  apply(
    transcriptElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'shift' },
  ) {
    let elements = transcriptElementsMutable;

    if (change.newIndex !== change.index) {
      const addedSegment = elements
        .slice(change.index, change.index + change.count)
        .map(
          (el) =>
            ({
              ...el,
              state: !el.state || el.state === 'added' ? 'added' : el.state,
            }) as TranscriptElement,
        );

      for (let i = 0; i < change.count; i++) {
        if (elements[change.index + i].state === 'added') {
          delete elements[change.index + i]; // holes in array
        } else {
          elements[change.index + i].state = 'cut';
        }
      }
      elements.splice(change.newIndex, 0, ...addedSegment);
    }

    const timeChange = change.timeShift;
    for (let j = change.newIndex; j < change.newIndex + change.count; j++) {
      const element = elements[j];
      if (element.type === 'text') {
        element.ts += timeChange;
        element.end_ts += timeChange;
      }
    }
    transcriptElementsMutable = elements.filter(Boolean);
  }
}

export default ShiftChangeHandler;
