import { makeAutoObservable, runInAction } from 'mobx';

import VideoCreatorStore from '../VideoCreatorStore'; // Adjust import path as needed
import {
  ExtraElementData,
  PunchListItem,
  StoryDTO,
} from '../../types.ts/story';

import { SidebarOption, MediaCard } from '../../types.ts/general';

import ChatGPTService from '../../services/ChatGPTService';

import { v4 as uuid } from 'uuid';
import { findClosestKey, getFuse } from '../../utility/fuse';

type ArtifactMatchingResponseItem = {
  photo_hunt_description: string;
  artifact_description: string;
  reason_for_match: string;
  similarity: string;
};

class PunchListManager {
  store: VideoCreatorStore;
  punchListData: PunchListItem[] | null = null;
  addedPunchListItemId: string | null = null;
  sidebarOptions = SidebarOption.media;
  mediaSubMenu = MediaCard.photo;

  constructor(store: VideoCreatorStore) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
  }

  length = (): number => {
    if (this.punchListData === null || !Array.isArray(this.punchListData))
      return 0;
    return this.punchListData.length;
  };

  set punchListItems(items: PunchListItem[]) {
    this.punchListData = items;
  }

  get punchListItems(): PunchListItem[] {
    if (this.length() === 0 || this.punchListData === null) return [];
    return [...this.punchListData];
  }

  findPunchListItemById(id: string): PunchListItem | undefined {
    return this.punchListData?.find((item) => item.id === id);
  }

  updatePunchListItem(id: string, changes: Partial<PunchListItem>): void {
    const item = this.findPunchListItemById(id);
    if (item) {
      Object.assign(item, changes);
    }
  }

  // Mutator: Remove a punch list item by ID
  removePunchListItem(id: string): void {
    this.punchListData =
      this.punchListData?.filter((item) => item.id !== id) || null;
    this.store.videoTranscriptionProcessor.removeSinglePhotoHighlight(id);
  }

  async addPhotoToPunchList(
    text: string,
    photo: string,
    description: string,
    startIndex: number,
    endIndex: number,
    startTime: number,
    endTime: number,
  ) {
    const punchListId = uuid();
    try {
      this.addedPunchListItemId = punchListId;

      const duration = endTime - startTime;
      const maxTrack = this.store.getMaxTrack();
      const currTrack = maxTrack + 1;
      const freeTrack = this.store.getFreeMediaTrack(
        'image',
        duration,
        startTime,
      );
      const punchListTrack = freeTrack || currTrack;

      const newPunchListData: PunchListItem = {
        id: punchListId,
        type: 'punch_list',
        sub_type: 'manual',
        description,
        line: text,
        artifactSrc: photo,
        transcriptPosition: {
          startIndex,
          endIndex,
        },
        metadata: {},
        evocative: '',
        dalleImages: [],
        elementId: '',
        dallePrompt: '',
        stockKeywords: '',
      };

      let punchListData = this.insertPunchListAtIndex([newPunchListData]);
      this.punchListData = punchListData;

      await this.addPunchListItemToTimeline(
        this.punchListData.find((p) => p.id === punchListId)!,
        duration,
        startTime,
        endTime,
        punchListTrack,
      );

      this.store.videoTranscriptionProcessor.addPhotoHighlight(
        startIndex,
        endIndex,
        punchListId,
      );
    } catch (error) {
      console.log('An error occurred: ', error);
      this.removePunchListItem(punchListId); // revert
    } finally {
      this.addedPunchListItemId = null;
    }
  }

  async addPunchListItemToTimeline(
    punchListItem: PunchListItem,
    duration: number,
    startTime: number,
    endTime: number,
    currTrack: number,
  ) {
    await this.store.createElement({
      id: punchListItem.id,
      type: 'image',
      source: punchListItem.artifactSrc || '',
      duration: duration,
      autoplay: true,
      fit: 'cover',
      smart_crop: true,
      track: currTrack,
      time: startTime,
      animations: [
        {
          start_scale: '100%',
          end_scale: '110%',
          x_anchor: '50%',
          y_anchor: '50%',
          fade: false,
          scope: 'element',
          easing: 'linear',
          type: 'scale',
          arbor_subType: 'zoomIn',
        },
      ],
    });
    punchListItem.startTime = startTime;
    punchListItem.endTime = endTime;
    punchListItem.duration = duration;
  }

  async attachPunchListPhotosToStory(): Promise<StoryDTO> {
    const storyDTO: StoryDTO = { ...this.store.story! };
    const srcUrls = (
      this.store.state?.elements?.map((e) => {
        if (e.source.type === 'image') return e.source.source;
      }) || []
    ).filter(Boolean);

    if (
      this.store.photoDataForDato &&
      Object.values(this.store.photoDataForDato)
    ) {
      for (let [id, blobData] of Object.entries(this.store.photoDataForDato)) {
        if (!srcUrls?.includes(blobData.url)) {
          continue;
        }

        const idx = this.punchListData?.findIndex((p) => p.id === id);
        if (idx && idx > -1) {
          if (!this.punchListData?.length) continue;

          if ('uploadId' in blobData && blobData.uploadId) {
            storyDTO.aiPhotos?.push({ id: blobData.uploadId });
            continue;
          }

          const line = this.punchListData[idx].line;
          const fileName = line.split(' ').join('_').toLowerCase().slice(0, 30);

          const newPhotoData = {
            ...blobData,
            fileName,
            alt: line,
            title: line,
            metaData: {
              alt: line,
              title: line,
              custom_data: {},
            },
          };

          const newUpload =
            await this.store.assetRepository?.uploadFile(newPhotoData);

          this.punchListData[idx] = {
            ...this.punchListData[idx],
            artifactSrc: newUpload!.url,
          };

          if (blobData.type === 'ai') {
            storyDTO.aiPhotos?.push(newUpload!);
          } else if (blobData.type === 'stock') {
            storyDTO.storyAssets?.push(newUpload!);
          }
        } else {
          if (!('fileName' in blobData)) continue;

          const newPhotoData = {
            ...blobData,
            alt: blobData.alt || '',
            title: blobData.title || '',
          };

          const newUpload =
            await this.store.assetRepository?.uploadFile(newPhotoData);

          if (blobData.type === 'stock') {
            storyDTO.storyAssets?.push(newUpload!);
          } else if (blobData.type === 'ai') {
            storyDTO.aiPhotos?.push(newUpload!);
          }
        }
      }
    }

    return storyDTO;
  }

  insertPunchListAtIndex = (
    newPunchListData: PunchListItem[],
    punchListData = this.punchListData,
  ) => {
    const sortedPunchList = newPunchListData.sort(
      (a, b) => a.transcriptPosition.endIndex - b.transcriptPosition.endIndex,
    );
    const lastPunchList = sortedPunchList[sortedPunchList.length - 1];
    const endIndex = lastPunchList.transcriptPosition.endIndex;

    const punchListLength = punchListData!.length;
    if (
      !punchListLength ||
      endIndex > punchListData![punchListLength - 1].transcriptPosition.endIndex
    ) {
      const existingPunchListData = punchListData || [];
      punchListData = [...existingPunchListData, ...sortedPunchList];
    } else {
      const indexToInsert = punchListData?.findIndex(
        (p) => p.transcriptPosition.endIndex > endIndex,
      );
      const punchListBefore = punchListData?.slice(0, indexToInsert!) || [];
      const punchListAfter = punchListData?.slice(indexToInsert!) || [];
      punchListData = [
        ...punchListBefore,
        ...sortedPunchList,
        ...punchListAfter,
      ];
    }
    return punchListData;
  };

  getPunchListTrack = () => {
    let punchListItemId: string | undefined = undefined;
    let extraElementData = this.store.currentVideo?.extraElementData || {};
    if (this.punchListData?.length) {
      punchListItemId = this.punchListData[0].id;
    } else if (Object.keys(extraElementData).length) {
      punchListItemId = Object.keys(extraElementData).find(
        (key) =>
          (extraElementData[key] as ExtraElementData | null)?.punchListData?.id,
      );
    }

    if (punchListItemId) {
      const element = this.store.renderer?.state?.elements?.find(
        (el) => el.source.id === punchListItemId,
      );

      return element?.track;
    }
  };

  addCutPhotoToPunchlist = async (
    existingId: string,
    head: {
      id: string;
      transcriptPosition: Record<'startIndex' | 'endIndex', number>;
    },
    tail: {
      id: string;
      transcriptPosition: Record<'startIndex' | 'endIndex', number>;
    },
  ) => {
    const idx = this.punchListData?.findIndex((p) => p.id === existingId);
    if (idx !== undefined && idx > -1) {
      const punchlistItem = this.punchListData![idx];
      const headItem = { ...punchlistItem, ...head };
      const tailItem = { ...punchlistItem, ...tail };
      this.punchListData!.splice(idx, 1, headItem, tailItem);
    }
  };

  addItemToPunchList = async (
    text: string,
    startIndex: number,
    endIndex: number,
    startTime: number,
    endTime: number,
  ) => {
    const punchListId = uuid();
    try {
      this.addedPunchListItemId = punchListId;
      this.sidebarOptions = SidebarOption.media;
      this.mediaSubMenu = MediaCard.photo;

      const duration = Math.min(8, endTime - startTime);
      const maxTrack = this.store.getMaxTrack();
      const currTrack = maxTrack + 1;
      // const punchListTrack = this.getPunchListTrack() || currTrack;
      const freeTrack = this.store.getFreeMediaTrack(
        'image',
        duration,
        startTime,
      );
      const punchListTrack = freeTrack || currTrack;

      const newPunchListData: PunchListItem = {
        id: punchListId,
        type: 'punch_list',
        sub_type: 'manual',
        description: '',
        line: text,
        transcriptPosition: {
          startIndex,
          endIndex,
        },
        metadata: {},
        evocative: '',
        dalleImages: [],
        elementId: '',
        dallePrompt: '',
        stockKeywords: '',
      };

      let punchListData = this.insertPunchListAtIndex([newPunchListData]);
      this.punchListData = punchListData;

      this.store.videoTranscriptionProcessor.addPhotoHighlight(
        startIndex,
        endIndex,
        punchListId,
      );

      const gptService = new ChatGPTService();

      const punchListItem: PunchListItem =
        await gptService.generatePunchListItem(text);

      if (punchListItem) {
        const updatedPunchList = this.punchListData!.map((p) =>
          p.id === punchListId
            ? {
                ...p,
                ...punchListItem,
              }
            : p,
        );
        this.punchListData = updatedPunchList;
      }

      await this.addPunchListItemToTimeline(
        this.punchListData.find((p) => p.id === punchListId)!,
        duration,
        startTime,
        endTime,
        punchListTrack,
      );
    } catch (error) {
      console.log('An error occurred: ', error);
      this.removePunchListItem(punchListId); // revert
    } finally {
      this.addedPunchListItemId = null;
    }
  };

  async matchArtifacts(isLoading: (value: boolean) => void) {
    const gptService = new ChatGPTService();
    gptService.matchArtifactsToPunchList(
      this.punchListItems,
      async (value, response) => {
        isLoading(value);
        if (!value) {
          try {
            await this.proceedWithArtifactMatching(
              this.unpackPromptResponse<ArtifactMatchingResponseItem>(
                response,
                'punchlist_to_artifact_matching',
              ),
            );
          } catch (error) {
            console.log('Failed to parse punchlist response', error);
          }
        }
      },
    );
  }

  private unpackPromptResponse<T>(response: string, key: string): T[] {
    const resp = JSON.parse(response);
    const args = JSON.parse(resp?.function_call?.arguments);
    return args?.[key];
  }

  async proceedWithArtifactMatching(response: ArtifactMatchingResponseItem[]) {
    response.sort((a, b) => parseInt(a.similarity) - parseInt(b.similarity));

    const validArtifacts =
      this.store.story?.storyArtifacts?.filter(
        (artifact) => artifact.title && artifact.responsiveImage,
      ) || [];
    const photoHuntDescriptionsFuse = getFuse(
      response?.map((res) => ({ key: res.photo_hunt_description })) || [],
    );
    const artifactDescriptionsFuse = getFuse(
      validArtifacts.map((a) => ({ key: a.title })),
    );

    const punchList = this.punchListItems;
    const foundArtifacts: string[] = [];
    for (let res of punchList) {
      const key = Object.keys(res)?.find(
        (key) => key.toLowerCase() === 'description',
      );
      if (!key) continue;

      const photoHuntDescriptionMatch = findClosestKey(
        photoHuntDescriptionsFuse,
        res.description,
      );
      const photoHuntPrompt = response?.find(
        (p) => p.photo_hunt_description === photoHuntDescriptionMatch,
      );
      if (
        !photoHuntPrompt ||
        !photoHuntPrompt.artifact_description ||
        parseInt(photoHuntPrompt.similarity) < 65
      ) {
        continue;
      }

      const artifactDescriptionsMatch = findClosestKey(
        artifactDescriptionsFuse,
        photoHuntPrompt.artifact_description,
      );
      const artifact = validArtifacts.find(
        (artifact) =>
          artifact.title.toLowerCase() ===
          artifactDescriptionsMatch?.toLowerCase(),
      );
      const artifactSrc =
        artifact?.responsiveImage?.srcSet || artifact?.url || '';

      if (!artifact || foundArtifacts.includes(artifactSrc)) continue;

      res.artifactSrc = artifactSrc;
      res.artifactDescription = artifact.title;
      res.matchReason = photoHuntPrompt.reason_for_match;
      res.similarityScore = photoHuntPrompt.similarity;

      foundArtifacts.push(artifactSrc);

      try {
        if (!res.id) {
          throw new Error('Punch list item ID not found');
        }
        await this.store.findOrReplaceInTimeline(
          res.id,
          res.artifactSrc,
          null,
          res.startTime,
        );
      } catch (error) {
        console.error('Failed to add artifact to timeline', error);
      }
    }

    runInAction(() => {
      this.punchListData = punchList;
    });
  }
}

export default PunchListManager;
