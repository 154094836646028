import { TranscriptChange } from '../../types.ts/transcript';
import ChangeHandler from './ChangeHandler';
import InsertPunctuationChangeHandler from './InsertPunctuationChangeHandler';
import InsertTextChangeHandler from './InsertTextChangeHandler';
import KaraokeBreaksChangeHandler from './KaraokeBreaksChangeHandler';
import MuteChangeHandler from './MuteChangeHandler';
import PhotoHighlightChangeHandler from './PhotoHighlightChangeHandler';
import RemoveChangeHandler from './RemoveChangeHandler';
import ReplaceChangeHandler from './ReplaceChangeHandler';
import RestoreChangeHandler from './RestoreChangeHandler';
import ShiftChangeHandler from './ShiftChangeHandler';
import UntrimEndChangeHandler from './UntrimEndChangeHandler';
import UntrimStartChangeHandler from './UntrimStartChangeHandler';

export default function getChangeHandler(
  change: TranscriptChange,
): ChangeHandler {
  switch (change.type) {
    case 'remove':
    case 'cut':
      return new RemoveChangeHandler();
    case 'replace':
      return new ReplaceChangeHandler();
    case 'shift':
      return new ShiftChangeHandler();
    case 'restore':
      return new RestoreChangeHandler();
    case 'photo_highlight':
      return new PhotoHighlightChangeHandler();
    case 'restore_mute':
    case 'mute':
      return new MuteChangeHandler();
    case 'insert_punct':
      return new InsertPunctuationChangeHandler();
    case 'insert_text':
      return new InsertTextChangeHandler();
    case 'untrim_start':
      return new UntrimStartChangeHandler();
    case 'untrim_end':
      return new UntrimEndChangeHandler();
    case 'add_karaoke_break':
    case 'remove_karaoke_break':
    case 'change_karaoke_end_break_time':
    case 'change_karaoke_start_break_time':
    case 'remove_all_karaoke_breaks':
      return new KaraokeBreaksChangeHandler();
  }
}
