import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { marked } from 'marked';
import { observer } from 'mobx-react-lite';
import { useUser } from '@clerk/clerk-react';
import lodash, { debounce } from 'lodash';

import { AiGeneratedContent, ContentViewData } from '../../../types.ts/story';
import ContentShare from '../../common/ContentShare';
import RegenerateButton from '../../common/RegenerateButton';
import SpinningLoading from '../../SpinningLoading';
import { videoCreator } from '../../../stores/VideoCreatorStore';
import PhotoModal from '../../common/PhotosModal';
import PhotoModalTop from '../../common/PhotoModalTop';
import {
  BlogOrEmailContent,
  ImageKey,
  ImageWithType,
} from '../../../types.ts/general';
import Modal from '../../Modal';
import { useOutsideAlerter } from '../../transcript/useClickOutside';
import CreateBlogUI from '../../common/CreateBlogUI';
import ContentSelector from '../../common/ContentSelector';
import { useFlagsCombination } from '../../../utility/useFlagsCombination';
import { BlogProducer } from './BlogProducer';
import SavedBlogList from './SavedBlogList';
import { initializeContentStudioContent } from '../../../utility/general';
import ChatGPTService from '../../../services/ChatGPTService';
import BlogEditor from './BlogEditor';

const gptService = new ChatGPTService();

const BlogView = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [contentHtml, setContentHtml] = useState<string | null>(null);

  const { story } = videoCreator;
  const [dragPosition, setDragPosition] = useState<Record<
    string,
    Record<'xAxis' | 'yAxis', string>
  > | null>(null);
  const [imageSize, setImageSize] = useState<Record<
    string,
    Record<'width' | 'height', number>
  > | null>(null);
  const [isResizing, setIsResizing] = useState<string | null>(null);
  const [openDropdown, toggleDropdown] = useState<number | null>(null);
  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [pageHash, setPageHash] = useState<string | null>(null);
  const [stockLoading, setStockLoading] = useState<boolean>(false);

  const userInfo = useUser();
  const blogContent = videoCreator.selectedBlogContent;
  const {
    contentStudioEnableBlogSave,
    contentStudioEnableBlogShare,
    enableBlogRegeneration,
  } = useFlagsCombination();

  const saved_blogs = videoCreator.story?.savedBlog;
  const blogProducer = new BlogProducer();

  const entries = blogProducer.getEntries();
  const [currentContent, setCurrentContent] =
    useState<BlogOrEmailContent | null>(blogProducer.getCurrentEntry(entries));

  useEffect(() => {
    initializeContentStudioContent(story?.id, 'Blog');
  }, [story?.id]);

  const data = videoCreator.contentStudioGeneratedContent?.Blog;

  const hasBeenGenerated = data?.hasBeenGenerated;
  let generatedContent = data?.content?.response || '';

  const handleCopyRichText = () => {
    if (!contentHtml) return;
    blogProducer.copyBlogContent(contentHtml);
  };

  const handleSave = async () => {
    if (!story?.id || !contentHtml) {
      return;
    }

    setIsSaving(true);
    const name = userInfo?.user?.fullName;
    const data = await blogProducer.saveBlogContent(name, contentHtml);

    if (data) {
      const { id, title, content, username } = data;
      setCurrentContent({
        id,
        title,
        content,
        username,
      });
      if (videoCreator.contentStudioGeneratedContent?.Blog) {
        videoCreator.contentStudioGeneratedContent.Blog.hasBeenGenerated =
          false;
      }
    }

    setIsSaving(false);
  };

  useEffect(() => {
    const generated_content = generatedContent as string;

    if (generatedContent && hasBeenGenerated) {
      const htmlData = blogProducer.produceHtmlFromGeneratedContent(
        generatedContent as string,
        editEnabled,
        imageSize,
        openDropdown,
        isResizing,
        dragPosition,
      );

      setContentHtml(htmlData);
      return;
    }

    if (currentContent?.id && saved_blogs) {
      const htmlData = blogProducer.produceHtmlFromSavedContent(
        currentContent.id,
        saved_blogs,
      );
      setContentHtml(htmlData || null);
    }

    if (currentContent?.id && saved_blogs) {
      const contentId = currentContent?.id;
      const data = saved_blogs?.[contentId]?.content;
      const hash = blogProducer.currentContentHash(generated_content, data);

      setPageHash(hash);
    }
  }, [currentContent, saved_blogs, generatedContent]);

  const spinnerText = () => {
    if (stockLoading) return 'Saving stock data...';
    if (isSaving) return 'Saving blog data...';
    if (hasBeenGenerated) return 'Regenerating blog...';
    return 'Generating blog...';
  };

  return (
    <>
      {(isLoading || isSaving || stockLoading) && (
        <SpinningLoading
          customStyle={{
            top: 0,
            position: 'fixed',
            alignItems: 'center',
          }}
          text={spinnerText()}
        />
      )}

      <Panel>
        <TopActions>
          {(Boolean(entries?.length) || blogContent) && (
            <ContentSelector
              entries={entries}
              currentContent={currentContent}
              setCurrentContent={setCurrentContent}
              type="savedBlog"
              enableCreateAndEditing={contentStudioEnableBlogSave}
            />
          )}
          {blogContent?.type && (
            <div style={{ marginLeft: 'auto' }} className="buttons">
              {enableBlogRegeneration && (
                <RegenerateButton
                  setIsLoading={setIsLoading}
                  storyId={story?.id}
                  transcriptionId={story?.transcription?.elementsJson?.id}
                  setImageSize={setImageSize}
                  promptTitle="Blog"
                  gptService={gptService}
                />
              )}
              {contentStudioEnableBlogShare && (
                <ContentShare type="Blog" onCopy={handleCopyRichText} />
              )}
              {/* <Save onClick={() => setEditEnabled(prev => !prev)}>Edit</Save> */}
              {contentStudioEnableBlogSave && (
                <Save onClick={handleSave}>Save</Save>
              )}
            </div>
          )}
        </TopActions>

        {!blogContent?.type && enableBlogRegeneration && (
          <Create>
            <CreateBlogUI
              setImageSize={setImageSize}
              supportedTitle="Blog"
              setIsLoading={setIsLoading}
              gptService={gptService}
            />
          </Create>
        )}

        {Boolean(entries?.length) && !blogContent && (
          <SavedBlogList
            entries={entries}
            currentContent={currentContent}
            setCurrentContent={setCurrentContent}
            type="savedBlog"
          />
        )}

        {videoCreator.selectedBlogContent && contentHtml ? (
          <Main>
            <Content>
              <BlogEditor
                content={contentHtml}
                onContentUpdate={(html: string) => {
                  setContentHtml(html);
                  videoCreator.contentStudioGeneratedContent = {
                    ...videoCreator.contentStudioGeneratedContent,
                    Blog: {
                      ...data,
                      hasBeenGenerated: true,
                      content: {
                        ...(data?.content || {}),
                        response: html,
                      },
                    },
                  } as ContentViewData;
                }}
              />
            </Content>
          </Main>
        ) : null}
      </Panel>
    </>
  );
});

export default BlogView;

const Panel = styled.div`
  .buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`;

const TopActions = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
`;

const Create = styled.div`
  margin-top: 80px;
`;

const Main = styled.div`
  margin: 15px auto;
  position: relative;
  border-radius: 8px;
  border: 1px solid #484848;
  max-height: 800px;
  overflow: scroll;
`;
const Content = styled.div`
  color: #030419;
  margin: 40px auto;
  padding: 10px 50px;
  border-radius: 5px;
  user-select: none;

  max-width: 800px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  h1 {
    text-align: center;
  }
  p strong {
    font-weight: 800;
  }
  & > p:has(img) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  blockquote {
    font-style: italic;
  }

  a {
    color: #030419;
  }
`;

const Save = styled.button`
  border: 0;
  outline: 0;
  background-color: #17c964;
  gap: 8px;
  width: 148px;
  height: 40px;
  border-radius: 10px;
  padding: 10px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #03041a;
  cursor: pointer;
`;
