import styled, { CSSProperties } from 'styled-components';
import { convertTimeToMMSS } from '../../utility/timeFormat';
import type MuxPlayerElement from '@mux/mux-player';
import { memo, useRef } from 'react';
import XIcon from '../../svgs/XIcon';
import FacebookIcon from '../../svgs/FacebookIcon';
import LinkedInIcon from '../../svgs/LinkedInIcon';
import { Tooltip } from 'react-tooltip';
import TiktokIcon from '../../svgs/TiktokIcon';
import InstagramIcon from '../../svgs/InstagramIcon';
import MuxPlayerClip from '../common/MuxPlayerClip';

const playerStyle = {
  maxHeight: '100%',
  width: '100%',
  height: '100%',
  borderRadius: 10,
  overflow: 'hidden',
  border: '1px solid #484848',
  aspectRatio: 4 / 3,
  '--pip-button': 'none',
  '--fullscreen-button': 'none',
  '--time-range': 'none',
  marginTop: '16px',
} as CSSProperties;

const AIClip = memo(
  ({
    fragment,
    isSelected,
    onSelect,
  }: {
    fragment: any;
    isSelected: boolean;
    onSelect: () => void;
  }) => {
    const playerRef = useRef<MuxPlayerElement | null>(null);
    const duration = Math.round(fragment.duration);
    const durationFormatted = duration ? convertTimeToMMSS(duration) : '';

    const description = fragment.text;
    const startTimeFormatted = convertTimeToMMSS(
      Math.round(fragment.startTime),
    );
    const endTime = fragment.startTime + fragment.duration;
    const endTimeFormatted = convertTimeToMMSS(
      Math.round(fragment.startTime + fragment.duration),
    );

    function renderPlatformIcon(platform: string) {
      if (platform === 'twitter') {
        return <XIcon />;
      } else if (platform === 'instagram') {
        return <InstagramIcon strokeColor="#F3E9D7" />;
      } else if (platform === 'tiktok') {
        return <TiktokIcon fillColor="#F3E9D7" />;
      } else if (platform === 'facebook') {
        return <FacebookIcon />;
      } else if (platform === 'linkedin') {
        return <LinkedInIcon />;
      } else {
        return <></>;
      }
    }

    return (
      <Card onClick={onSelect}>
        <SelectButton active={isSelected}>{isSelected ? '✓' : ''}</SelectButton>
        <div
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          <DurationBadge>{durationFormatted}</DurationBadge>
          {renderPlatformIcon(fragment.platform)}
        </div>
        <MuxPlayerClip clip={fragment} />
        <Description
          data-tooltip-id="description-tooltip"
          data-tooltip-content={description}
        >
          {description}
        </Description>
        <Tooltip
          id="description-tooltip"
          style={{
            width: '260px',
            padding: '5px',
            fontSize: '10px',
            lineHeight: '120%',
            zIndex: '10',
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TimeRanges>
            {startTimeFormatted} - {endTimeFormatted}{' '}
          </TimeRanges>
          <Theme>{fragment.theme}</Theme>
        </div>
      </Card>
    );
  },
);

export default AIClip;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 286px;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid #484848;
  box-sizing: border-box;
  color: #f3e9d7;
  height: fit-content;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 4px #484848c0;
  }
`;

const SelectButton = styled.div.attrs((props: { active: boolean }) => props)`
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  right: 16px;
  top: 16px;
  margin: auto;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 0px 0px 0 0;
  box-sizing: border-box;
  color: #484848;
  border: 1px solid #484848;
  ${(props) =>
    props.active &&
    `
      color: #45d483;
      border: 1px solid #45d483;
    `}
`;

const DurationBadge = styled.div`
  padding: 2px 5px;
  background-color: #f178b6;
  color: black;
  width: fit-content;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
`;

const Description = styled.div`
  height: 40px;
  color: #f3e9d7;
  font-size: 10px;
  font-weight: 400;
  line-height: 140%; /* 14px */
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 6px 0 16px 0;
`;

const Theme = styled.div`
  width: fit-content;
  padding: 8px;
  color: #484848;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #484848;
  text-transform: capitalize;
`;

const TimeRanges = styled.div`
  color: #484848;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
`;
