type ProductionInfo = {
  uuid: string;
};

export async function startProductionToRemoveMusicFromVideo({
  inputFileUrl,
}: {
  inputFileUrl: string;
}) {
  return makeRequestToAuphonic<ProductionInfo>('productions', 'POST', {
    preset: 'rz6RvGUBRDy495mQ8K4PNC',
    input_file: inputFileUrl,
    action: 'start',
    webhook: `${process.env.REACT_APP_API_URL}/api/webhooks/auphonic-production`,
  });
}

async function makeRequestToAuphonic<T>(
  endpoint: string,
  method: 'GET' | 'POST' = 'GET',
  body?: any,
): Promise<T> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/forward/auphonic`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...body,
        method,
        endpoint,
      }),
    },
  );
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return (await response.json()).data;
}
