import React, { useEffect, useMemo, useRef, useState } from 'react';
import JoditEditor, { Jodit } from 'jodit-react';
import PhotoModal from '../../common/PhotosModal';
import PhotoModalTop from '../../common/PhotoModalTop';
import Modal from '../../Modal';
import CropModal from './CropModal';
import 'react-image-crop/dist/ReactCrop.css';

const BlogEditor: React.FC<{
  content: string;
  onContentUpdate: (html: string) => void;
}> = ({ content, onContentUpdate }) => {
  const editor = useRef<Jodit>(null);
  const [cropModalOpen, setCropModalOpen] = useState<boolean>(false);
  const [openMedia, toggleMedia] = useState<boolean>(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [selectedNode, setSelectedNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const handleButtonClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.tagName === 'IMG') {
        setSelectedNode(target);
        const src = target.getAttribute('src') || '';
        setCroppedImageUrl(src);
      }

      if (target.closest('.jodit-toolbar-button_pencil')) {
        toggleMedia(true);
      }
    };

    document.addEventListener('click', handleButtonClick);
    return () => {
      document.removeEventListener('click', handleButtonClick);
    };
  }, []);

  useEffect(() => {
    const tooltip = document.querySelector(
      '.jodit-popup__content',
    ) as HTMLElement | null;
    if (tooltip) {
      tooltip.style.display = cropModalOpen ? 'none' : 'block';
    }
  }, [cropModalOpen]);

  const replaceImageUrl = (imgNode: HTMLImageElement, newUrl: string): void => {
    const doc = new window.DOMParser().parseFromString(content, 'text/html');
    const docImages = doc.getElementsByTagName('img');
    for (let i = 0; i < docImages.length; i++) {
      const docImg = docImages[i];
      if (docImg.src !== imgNode.src) {
        continue;
      }

      docImg.setAttribute('src', newUrl);
      docImg.removeAttribute('width');
      docImg.removeAttribute('height');
      docImg.setAttribute('data-skip-replacement', 'true');
      onContentUpdate(doc.body.innerHTML);

      break;
    }
  };

  const handleReplaceAction = () => {
    if (selectedNode && croppedImageUrl) {
      console.log('replace', selectedNode, croppedImageUrl);
      replaceImageUrl(selectedNode as HTMLImageElement, croppedImageUrl);
      toggleMedia(false);
    }
  };

  const handleDoubleClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.tagName === 'IMG') {
      setSelectedNode(target);
      setCropModalOpen(true);
    }
  };

  const handleSaveCroppedImage = (croppedImageUrl: string) => {
    if (selectedNode) {
      replaceImageUrl(selectedNode as HTMLImageElement, croppedImageUrl);
      setCroppedImageUrl(croppedImageUrl);
      setCropModalOpen(false);
    }
  };

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: 'Start typing...',
      disablePlugins: 'image-properties',
      events: {
        dblclick: handleDoubleClick,
      },
    }),
    [],
  );

  const originalImageUrl = croppedImageUrl?.split('?')[0] || null;

  return (
    <>
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        onBlur={(newContent) => onContentUpdate(newContent)}
        onChange={(newContent) => {}}
      />

      {openMedia && (
        <Modal
          isOpen={true}
          onClose={() => toggleMedia(false)}
          paddingHorizontal="0"
        >
          <PhotoModal
            TopComponent={
              <PhotoModalTop
                title="blog"
                isSelected={!!croppedImageUrl}
                replaceAction={handleReplaceAction}
              />
            }
            otherFields={['stock']}
            onCloseSelf={() => toggleMedia(false)}
            openPrevModal={() => {}}
            selectedImageUrl={originalImageUrl}
            setSelectedImage={(img) => {
              if (!img) return;
              console.log('setSelectedImage', img);
              setCroppedImageUrl(img.url);
            }}
            from="quotecard"
          />
        </Modal>
      )}

      {cropModalOpen && croppedImageUrl && (
        <CropModal
          isOpen={cropModalOpen}
          onClose={() => setCropModalOpen(false)}
          selectedImage={croppedImageUrl}
          onSave={handleSaveCroppedImage}
        />
      )}
    </>
  );
};

export default BlogEditor;
