import { makeAutoObservable } from 'mobx';
import {
  KARAOKE_TRACK_NUMBER,
  videoCreator,
} from '../../stores/VideoCreatorStore';
import {
  DatoColor,
  ExtraElementData,
  GenericTextBranding,
  KaraokeTextBranding,
  LowerThirdTextBranding,
} from '../../types.ts/story';
import { AspectRatio } from '../../types.ts/video';
import { KaraokeConfig } from '../../videoTranscriptionProcessor/types/karaokeTypes';
import { DEFAULT_KARAOKE_CONFIG } from '../../videoTranscriptionProcessor/constants/karaokeConstants';
import { ElementState } from '../../renderer/ElementState';
import { convertToPixels } from '../../videoTranscriptionProcessor/utils';
import _isEqual from 'lodash/isEqual';
import { DEFAULT_TEXT_CONFIG } from './TextProducer';
import { SidebarOption, MediaCard } from '../../types.ts/general';

type GenericBrandingKeys =
  | 'title'
  | 'font_family'
  | 'font_size'
  | 'fill_color'
  | 'background_color'
  | 'background_transparency'
  | 'font_weight'
  | 'x_alignment'
  | 'italic'
  | 'uppercase'
  | 'aspect_ratio';

type LowerThirdBrandingKeys =
  | GenericBrandingKeys
  | 'enter_animation'
  | 'exit_animation'
  | 'enter_animation_length'
  | 'exit_animation_length';

type KaraokeBrandingKeys =
  | GenericBrandingKeys
  | 'animation'
  | 'x_pos'
  | 'y_pos'
  | 'instagram_lines'
  | 'stroke_width'
  | 'stroke_color'
  | 'hide_comma'
  | 'hide_period'
  | 'hide_fillers';

type BrandingKeys = LowerThirdBrandingKeys | KaraokeBrandingKeys;
type KaraokeTemplate = Record<
  keyof KaraokeTextBranding,
  string | number | boolean | null
> & { type: 'karaoke' | 'basic' };
export type LowerThirdTemplate = Record<
  keyof LowerThirdTextBranding,
  string | number | boolean | null
> & { type: 'karaoke' | 'basic' };

export type Template = Record<
  keyof (LowerThirdTextBranding | KaraokeTextBranding),
  string | number | boolean | null
> & { type: 'karaoke' | 'basic' };

const COLOR_BRANDING = ['fillColor', 'backgroundColor', 'strokeColor'];

const BACKGROUND_COLORS = ['backgroundColor'];

export class TextBrandingService {
  templateName: string | undefined = undefined;
  isLoading: boolean = false;
  brandingTemplates: Template[] = [];

  selectedBrandingTemplate: Template | undefined = undefined;
  selectedTemplateId: string = 'default';
  lastUsedTemplateId: string = 'default';
  isPropsChanged = false;
  isTemplateModified = false;
  prepareTemplateToSave = false;
  closeActiveState = false;
  modifiedTemplateData: {
    template: Template;
    config: ElementState['source'];
  } | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  getBasicTextConfig() {
    const aspectRatio =
      videoCreator.currentVideo?.aspectRatio || AspectRatio.AR_16_9;
    const basicTextSetting =
      videoCreator.textProducer.getBasicTextSettingByAspectRatio(aspectRatio);
    return {
      id: 'default',
      title: 'Arbor Default',
      fontFamily: DEFAULT_TEXT_CONFIG.font_family,
      fontSize: basicTextSetting.font_size,
      fillColor: DEFAULT_TEXT_CONFIG.fill_color,
      enterAnimation: 'fade',
      enterAnimationLength: 1,
      exitAnimation: 'fade',
      exitAnimationLength: 1,
      backgroundTransparency: 70,
      backgroundColor: DEFAULT_TEXT_CONFIG.background_color,
      fontWeight: 600,
      yPos: basicTextSetting.y,
      xAlignment: '0%',
      uppercase: false,
      italic: false,
      length: DEFAULT_TEXT_CONFIG.duration,
      aspectRatio,
      type: 'basic',
    };
  }

  getKaraokeTextConfig() {
    const aspectRatio =
      videoCreator.currentVideo?.aspectRatio || AspectRatio.AR_16_9;
    const basicTextSetting =
      videoCreator.karaokeProducer.getKaraokeTextSettingByAspectRatio(
        aspectRatio,
      );
    const fontSize = videoCreator.textProducer.convertToVh(
      basicTextSetting.font_size,
    );
    return {
      id: 'default',
      title: 'Arbor Default',
      fontFamily: DEFAULT_KARAOKE_CONFIG.font_family,
      fontSize: basicTextSetting.font_size,
      fillColor: DEFAULT_KARAOKE_CONFIG.fill_color,
      backgroundColor: DEFAULT_KARAOKE_CONFIG.background_color,
      backgroundTransparency: '0.8',
      strokeColor: DEFAULT_KARAOKE_CONFIG.stroke_color,
      strokeWidth: DEFAULT_KARAOKE_CONFIG.stroke_width,
      fontWeight: DEFAULT_KARAOKE_CONFIG.font_weight,
      yPos: basicTextSetting.y,
      xPos: DEFAULT_KARAOKE_CONFIG.x,
      xAlignment: DEFAULT_KARAOKE_CONFIG.x_alignment,
      uppercase: DEFAULT_KARAOKE_CONFIG.text_transform === 'uppercase' || false,
      italic: DEFAULT_KARAOKE_CONFIG.font_style === 'italic' || false,
      instagramLines: DEFAULT_KARAOKE_CONFIG.instagramLines,
      aspectRatio:
        videoCreator.currentVideo?.aspectRatio || AspectRatio.AR_16_9,
      hideComma: DEFAULT_KARAOKE_CONFIG.hideComma,
      hidePeriod: DEFAULT_KARAOKE_CONFIG.hidePeriod,
      hideFillers: DEFAULT_KARAOKE_CONFIG.hideFillers,
      animation: 'none',
      type: 'karaoke',
    };
  }

  public hasConfigChanged(type: 'basic' | 'karaoke') {
    type Config = ElementState['source'] | KaraokeConfig;
    let initialConfig = {} as Config;
    let config = {} as Config;
    if (type === 'basic') {
      initialConfig = videoCreator.textProducer.initializeConfig();
      config = videoCreator.textProducer.getTextConfig();
    } else {
      initialConfig = DEFAULT_KARAOKE_CONFIG;
      config = videoCreator.karaokeProducer.getKaraokeConfig();
    }
    const extranous_fields = this.getExtraneousFields(type);

    const newInitConfig = { ...initialConfig };
    const newConfig = { ...config };

    for (let f of extranous_fields) {
      delete newConfig[f as keyof Config];
      delete newInitConfig[f as keyof Config];
    }

    return !_isEqual(newInitConfig, newConfig);
  }

  public checkPropsChanged(
    type: 'basic' | 'karaoke',
    propertyMap: ElementState['source'] | KaraokeConfig,
    switched_template = false,
    ignore_keys = false,
  ) {
    const keys = Object.keys(propertyMap);
    const extranous_fields = this.getExtraneousFields(type);

    this.templateModified(type, propertyMap, ignore_keys);

    const karaokeAlignmentConditions =
      type === 'karaoke' &&
      keys.length === 2 &&
      keys.includes('x') &&
      keys.includes('x_alignment');

    if (
      this.selectedTemplateId === 'default' &&
      !switched_template &&
      (keys.length === 1 || karaokeAlignmentConditions || ignore_keys) &&
      (!extranous_fields.includes(keys[0]) || ignore_keys) &&
      !this.prepareTemplateToSave
    ) {
      this.isPropsChanged = true;
    }
  }

  private toggleModifiedTemplateData() {
    this.isTemplateModified = false;
    this.modifiedTemplateData = null;
  }

  private setModifiedTemplateData(type: 'basic' | 'karaoke' = 'basic') {
    const template = this.selectedBrandingTemplate!;

    let config = videoCreator.karaokeProducer.getKaraokeConfig() as
      | KaraokeConfig
      | ElementState['source'];

    if (type === 'basic') {
      config = videoCreator.textProducer.getTextConfig();
      const activeElement = videoCreator.getActiveElement();
      config.text = activeElement?.source.text || '';
    }
    this.isTemplateModified = true;
    this.modifiedTemplateData = {
      template,
      config,
    };
  }

  public templateModified(
    type: 'basic' | 'karaoke',
    propertyMap: ElementState['source'] | KaraokeConfig,
    ignore_keys = false,
  ) {
    if (
      this.selectedTemplateId === 'default' ||
      this.selectedTemplateId === 'custom'
    )
      return;
    const keys = Object.keys(propertyMap);
    const extranous_fields = this.getExtraneousFields(type);

    if (this.prepareTemplateToSave) return;

    if (ignore_keys) {
      this.setModifiedTemplateData(type);
      return;
    }

    if (
      (keys.length === 1 && !extranous_fields.includes(keys[0])) ||
      type === 'karaoke'
    ) {
      this.setModifiedTemplateData(type);
    }
  }

  private getExtraneousFields(type: 'basic' | 'karaoke') {
    if (type === 'karaoke') {
      return ['id', 'track', 'type', 'text', 'time', 'duration'];
    }
    return ['id', 'track', 'type', 'text', 'time', 'duration', 'x', 'y'];
  }

  private getBrandingProps(
    branding: LowerThirdTextBranding | KaraokeTextBranding,
    type: 'basic' | 'karaoke' = 'basic',
  ) {
    const values = {} as Record<
      keyof (LowerThirdTextBranding | KaraokeTextBranding),
      string | number | boolean
    > & { type: 'basic' | 'karaoke' };
    for (let k in branding) {
      const key = k as keyof (LowerThirdTextBranding | KaraokeTextBranding);
      if (key === 'backgroundTransparency') continue;
      if (!COLOR_BRANDING.includes(key)) {
        values[key] = branding[key] as string | number | boolean;
        continue;
      }

      const color = branding[key] as DatoColor;
      if (!color) {
        values[key] = '';
        continue;
      }

      let alpha = parseFloat((color.alpha / 255).toFixed(2));
      if (BACKGROUND_COLORS.includes(key)) {
        const defaultTransparency = branding.backgroundTransparency;
        if (defaultTransparency) alpha = defaultTransparency / 100;
      }
      values[key] =
        `rgba(${color.red}, ${color.green}, ${color.blue}, ${alpha})`;
      values.type = type;
    }
    return values;
  }

  public loadLastUsedTemplate(
    type: 'basic' | 'karaoke' = 'basic',
    aspect_ratio: GenericTextBranding['aspectRatio'] = AspectRatio.AR_16_9,
  ) {
    const lastUsedTemplates =
      videoCreator.organization?.lastUsedTemplates || [];
    const lastUsedTemplate = lastUsedTemplates.find(
      (t) => t.type === type && t.aspectRatio === aspect_ratio,
    );
    let isInTemplates = this.brandingTemplates.some(
      (t) => t.id === lastUsedTemplate?.id,
    );
    if (isInTemplates) {
      this.lastUsedTemplateId = lastUsedTemplate!.id;
    } else {
      const defaultTemplate = this.brandingTemplates.find(
        (t) => t.id === 'default',
      );
      this.lastUsedTemplateId = defaultTemplate!.id as string;
    }
  }

  private setLastUsedTemplate(
    templateId: string,
    title: string,
    type: 'basic' | 'karaoke' = 'basic',
    aspect_ratio: GenericTextBranding['aspectRatio'] = AspectRatio.AR_16_9,
  ) {
    if (!videoCreator.organization) return;
    const lastUsedTemplates =
      videoCreator.organization?.lastUsedTemplates || [];

    const idx = lastUsedTemplates.findIndex(
      (l) => l.aspectRatio === aspect_ratio && l.type === type,
    );
    const data = {
      id: templateId,
      type,
      aspectRatio: aspect_ratio,
      title,
    };
    if (idx > -1) {
      lastUsedTemplates[idx] = data;
    } else {
      lastUsedTemplates.push(data);
    }
    videoCreator.organization!.lastUsedTemplates = lastUsedTemplates;
    return lastUsedTemplates;
  }

  private loadAllTemplates(
    type: 'basic' | 'karaoke' = 'basic',
    aspect_ratio: GenericTextBranding['aspectRatio'] = AspectRatio.AR_16_9,
  ) {
    let templates: Template[] = [];
    if (type === 'basic') {
      const textBrandings =
        videoCreator.organization?.lowerThirdTextBranding
          ?.filter((b) => b.aspectRatio === aspect_ratio)
          ?.map((branding) => {
            return this.getBrandingProps(branding, type);
          }) || [];

      const defaultProps = this.getBasicTextConfig();
      const customProps = { ...defaultProps, id: 'custom', title: 'Custom' };
      templates = [defaultProps, customProps, ...textBrandings] as Template[];
    } else {
      const textBrandings =
        videoCreator.organization?.karaokeTextBranding
          ?.filter((b) => b.aspectRatio === aspect_ratio)
          ?.map((branding) => {
            return this.getBrandingProps(branding, type);
          }) || [];

      const defaultProps = this.getKaraokeTextConfig();
      const customProps = { ...defaultProps, id: 'custom', title: 'Custom' };
      templates = [defaultProps, customProps, ...textBrandings] as Template[];
    }
    console.trace('Templates: ', templates, aspect_ratio);
    this.brandingTemplates = templates;
  }

  public getAllBrandingTemplates(
    type: 'basic' | 'karaoke' = 'basic',
    aspect_ratio: GenericTextBranding['aspectRatio'] = AspectRatio.AR_16_9,
    hasActiveElement: boolean = false,
    onKaraokeInitialize = false,
    source: ElementState['source'] | null = null,
  ) {
    this.loadAllTemplates(type, aspect_ratio);
    this.loadLastUsedTemplate(type, aspect_ratio);
    const templates = this.brandingTemplates;

    let templateId = this.selectedTemplateId || this.lastUsedTemplateId;

    if (hasActiveElement) {
      templateId = this.getTemplateIdOnSourceChange(type) as string;

      if (!templateId) templateId = 'custom';
    } else {
      if (templateId === 'custom') templateId = 'default';
    }

    const template = templates.find((t) => t.id === templateId);
    console.log('template', template?.id, hasActiveElement, type);
    if (template) {
      this.selectedBrandingTemplate = template;
      this.selectedTemplateId = templateId;
    } else {
      const _template = hasActiveElement ? templates[1] : templates[0];
      this.selectedBrandingTemplate = _template;
      this.selectedTemplateId = _template.id as string;

      console.warn(
        'Branding template not found could be due to change in aspect ratio - revert to default:',
        templateId,
      );
    }

    if (
      this.selectedTemplateId !== 'custom' &&
      !onKaraokeInitialize &&
      !hasActiveElement
    ) {
      this.applyTemplate(this.selectedBrandingTemplate!, type, source);
    }
    this.closeActiveState = true;
  }

  public getTemplateIdOnSourceChange(
    type: 'basic' | 'karaoke' = 'basic',
    configId: string | null = null,
  ) {
    if (type === 'basic') {
      const config = videoCreator.textProducer.getTextConfig();
      const id = configId || (config.id as string);
      if (id) {
        return (videoCreator.currentVideo?.extraElementData as ExtraElementData)
          ?.lowerThirdTemplates?.[id] as string;
      }
    } else {
      return (videoCreator.currentVideo?.extraElementData as ExtraElementData)
        ?.karaokeTemplateId as string;
    }
  }

  public switchTemplateByType(selectedElementsIds: string[]) {
    if (selectedElementsIds.length !== 1) return;
    const elementId = selectedElementsIds[0];
    const element = videoCreator.renderer
      ?.getElements()
      .find((e) => e.source.id === elementId);
    if (!element) return;

    if (this.isTemplateModified) {
      this.isTemplateModified = false;
    }

    if (
      element.source.type === 'text' ||
      element.source.track === KARAOKE_TRACK_NUMBER
    ) {
      const type =
        element.source.track === KARAOKE_TRACK_NUMBER ? 'karaoke' : 'basic';
      if (type === 'basic') {
        this.closeActiveState = true;
      }

      if (element.source.type !== KARAOKE_TRACK_NUMBER) {
        videoCreator.textProducer.setTextConfig(element.source);
      }

      this.switchTemplateOnSourceChange(type, elementId, element);
    }
  }

  public switchTemplateOnSourceChange(
    type: 'basic' | 'karaoke' = 'basic',
    elementId: string | null = null,
    element: ElementState,
  ) {
    if (type === 'basic' && videoCreator.textProducer.creatingNewText) {
      videoCreator.textProducer.creatingNewText = false;
      return;
    }
    let templateId = this.getTemplateIdOnSourceChange(
      type,
      elementId,
    ) as string;

    if (templateId === this.selectedTemplateId) return;

    if ((templateId === 'custom' || templateId === 'default') && elementId) {
      this.selectedTemplateId = templateId;
      this.selectedBrandingTemplate = this.brandingTemplates.find(
        (t) => t.id === templateId,
      );
      if (element.source.track !== KARAOKE_TRACK_NUMBER) {
        videoCreator.textProducer.setTextConfig(element.source!);
      }
      return;
    }

    const template = this.brandingTemplates.find((t) => t.id === templateId);

    if (template) {
      this.selectedTemplateId = templateId;
      this.selectedBrandingTemplate = template;
      this.applyTemplate(this.selectedBrandingTemplate!, type, element.source);
    } else {
      this.selectedTemplateId = 'custom';
      this.selectedBrandingTemplate = this.brandingTemplates[1];
    }
  }

  public selectBrandingTemplate(type: 'basic' | 'karaoke', templateId: string) {
    const template = this.brandingTemplates.find((t) => t.id === templateId);
    if (template) {
      this.selectedTemplateId = templateId;
      this.selectedBrandingTemplate = template;
      this.saveToExtraElementData(type, templateId);
    }
  }

  public splitRgbaString(rgba: string, skipError = false) {
    if (!rgba) return [];
    if (!rgba.includes('rgb')) {
      if (skipError) return [];
      throw new Error('Bad rgba value');
    }
    if (rgba.includes('rgba'))
      return rgba
        .substring(5, rgba.length - 1)
        .split(',')
        .map((value) => value.trim());

    const value = rgba
      .substring(4, rgba.length - 1)
      .split(',')
      .map((value) => value.trim());
    return [...value, '1'];
  }

  public rgbaToHex(rgba: string, skipError = false) {
    const rgbaValues = this.splitRgbaString(rgba, skipError);
    if (rgbaValues.length === 0) return '';
    const hexValues = rgbaValues.slice(0, 3).map((value) => {
      const hex = Number(value).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    });
    const alphaHex = Math.round(parseFloat(rgbaValues[3]) * 255).toString(16);
    const hexAlpha = (alphaHex.length === 1 ? '0' : '') + alphaHex;
    if (hexAlpha.toLowerCase() === 'ff') return '#' + hexValues.join('');

    return '#' + hexValues.join('') + hexAlpha;
  }

  private getRgbaValueFromRgbaString(color: string) {
    if (!color) return null;
    const [red, green, blue, alpha] = this.splitRgbaString(color, true);
    return {
      red: parseInt(red),
      green: parseInt(green),
      blue: parseInt(blue),
      alpha: parseFloat(alpha) * 255,
    };
  }

  public addAlphaToRgba(rgba: string, alpha: string | undefined) {
    if (!alpha) return rgba;
    const rgbaValues = rgba
      .substring(5, rgba.length - 1)
      .split(',')
      .map((value) => value.trim());

    return `rgba(${rgbaValues[0]}, ${rgbaValues[1]}, ${rgbaValues[2]}, ${alpha})`;
  }

  public rgbaWithSolidAlpha(rgba: string) {
    if (!rgba) return '';
    const rgbaValues = this.splitRgbaString(rgba, true);
    return `rgba(${rgbaValues[0]}, ${rgbaValues[1]}, ${rgbaValues[2]}, 1)`;
  }

  public snakeToCamel(str: string) {
    return str.replace(/_([a-z])/g, function (match, letter) {
      return letter.toUpperCase();
    });
  }

  public camelToSnake(str: string): string {
    return str.replace(/[A-Z]/g, (letter, index) => {
      return index === 0 ? letter.toLowerCase() : `_${letter.toLowerCase()}`;
    });
  }

  getColorInColorCode(color: string) {
    if (color === 'white') return 'rgba(255, 255, 255, 1)';
    if (color === 'black') return 'rgba(0, 0, 0, 1)';
    if (color === 'transparent') return null;
    return color;
  }

  private getGenericBrandingValues(
    config: KaraokeConfig | Record<string, any>,
    title: string,
    type: 'basic' | 'karaoke' = 'basic',
  ) {
    const values: Partial<
      Record<BrandingKeys, string | boolean | number | null | DatoColor>
    > = {
      aspect_ratio:
        videoCreator.currentVideo?.aspectRatio || AspectRatio.AR_16_9,
      title,
    };

    const directFields = ['font_family', 'font_weight', 'x_alignment'];
    const COLOR_BRANDING = ['fillColor', 'backgroundColor'];

    if (type === 'karaoke') {
      directFields.push('stroke_width');
      COLOR_BRANDING.push('strokeColor');
    }

    for (let [key, value] of Object.entries(config)) {
      if (key === 'font_size') {
        values[key as BrandingKeys] = videoCreator.textProducer
          .getFontSize(value as string)
          .toString();
      }
      if (directFields.includes(key)) {
        values[key as BrandingKeys] = value.toString();
      }

      // if (key === 'y') {
      //   values.y_pos = parseInt(value as string);
      //   continue;
      // }

      // if (key === 'x') {
      //   values.x_pos = parseInt(value as string);
      //   continue;
      // }

      if (key === 'text_transform') {
        values.uppercase = value === 'uppercase';
      }

      if (key === 'font_style') {
        values.italic = value === 'italic';
      }

      if (COLOR_BRANDING.includes(this.snakeToCamel(key))) {
        if (!value) {
          values[key as BrandingKeys] = null;
        }
        const color = this.getRgbaValueFromRgbaString(
          this.getColorInColorCode(value) as string,
        );

        values[key as BrandingKeys] = color as DatoColor;

        if (key === 'background_color' && color) {
          values.background_transparency = (color.alpha / 255) * 100;
        }
      }
    }

    return values;
  }

  private formatLowerThirdBrandingUpdateFields(
    config: Record<string, any>,
    title: string,
  ) {
    const values = this.getGenericBrandingValues(config, title, 'basic');

    const enterAnimation = config?.animations?.find(
      (a: any) => a.time === 'start' || a.time === 0,
    );
    if (enterAnimation) {
      values.enter_animation = enterAnimation.type;
      values.enter_animation_length = enterAnimation.duration;
    }

    const exitAnimation = config?.animations?.find(
      (a: any) => a.time === 'end',
    );
    if (exitAnimation) {
      values.exit_animation = exitAnimation.type;
      values.exit_animation_length = exitAnimation.duration;
    }

    return values;
  }

  private formatKaraokeBrandingUpdateFields(
    config: KaraokeConfig | Record<string, any>,
    title: string,
  ) {
    const values = this.getGenericBrandingValues(config, title, 'karaoke');

    values.hide_comma = !!config.hideComma;
    values.hide_fillers = !!config.hideFillers;
    values.hide_period = !!config.hidePeriod;

    if (config.x) {
      values.x_pos = Math.max(0, Math.min(100, parseFloat(config.x as string)));
    }

    if (config.y) {
      values.y_pos = Math.max(0, Math.min(100, parseFloat(config.y as string)));
    }

    if (config.instagramEffect) {
      values.animation = 'instagram';
    } else {
      const animation = config.animations?.find(
        (a: any) => a.time === 'start' || a.time === 0,
      );
      if (animation) values.animation = animation.type;
    }

    if (config.instagramLines)
      values.instagram_lines = config.instagramLines.toString();

    return values;
  }

  private formatBrandingUpdateFields(
    config: KaraokeConfig | Record<string, any>,
    title: string,
    type: 'basic' | 'karaoke' = 'basic',
  ) {
    if (type === 'basic') {
      return this.formatLowerThirdBrandingUpdateFields(config, title);
    }
    return this.formatKaraokeBrandingUpdateFields(config, title);
  }

  private formatOrganizationBrandingFields(
    config: KaraokeConfig | Record<string, any>,
    title: string,
    type: 'basic' | 'karaoke' = 'basic',
    id?: string,
  ) {
    type TextBranding = KaraokeTextBranding | LowerThirdTextBranding;

    const fields = this.formatBrandingUpdateFields(config, title, type);
    let values = { id } as TextBranding;

    for (let [k, value] of Object.entries(fields)) {
      const key = this.snakeToCamel(k) as keyof TextBranding;
      values = { ...values, [key]: value || undefined };
      if (COLOR_BRANDING.includes(key)) {
        const colorValue = this.getColorInColorCode(
          config[k as keyof KaraokeConfig] as string,
        );
        console.log('colorValue:', colorValue);

        if (!colorValue) {
          values = { ...values, [key as keyof TextBranding]: null };
          continue;
        }

        const [red, green, blue, alpha] = this.splitRgbaString(
          colorValue as string,
          true,
        );

        const datoColor: DatoColor = {
          red: parseInt(red),
          green: parseInt(green),
          blue: parseInt(blue),
          alpha: parseFloat(alpha) * 255,
          hex: this.rgbaToHex(colorValue as string, true),
          cssRgb: `rgb(${red} ${green} ${blue} / ${alpha})`,
        };
        values = { ...values, [key as keyof TextBranding]: datoColor };
      }
    }

    const branding =
      type === 'basic'
        ? videoCreator.organization?.lowerThirdTextBranding || []
        : videoCreator.organization?.karaokeTextBranding || [];
    console.log('branding', branding);
    console.log('values', values);

    const brandingIdx = branding.findIndex((b) => b.id === id);

    let updatedOrganization = videoCreator.organization!;

    if (brandingIdx > -1) {
      branding[brandingIdx] = values;
      if (type === 'karaoke') {
        updatedOrganization.karaokeTextBranding =
          branding as KaraokeTextBranding[];
      } else {
        updatedOrganization.lowerThirdTextBranding =
          branding as LowerThirdTextBranding[];
      }
    } else {
      updatedOrganization = {
        ...videoCreator.organization!,
        ...(type === 'basic'
          ? {
              lowerThirdTextBranding: [
                ...(videoCreator.organization?.lowerThirdTextBranding || []),
                values as LowerThirdTextBranding,
              ],
            }
          : {
              karaokeTextBranding: [
                ...(videoCreator.organization?.karaokeTextBranding || []),
                values as KaraokeTextBranding,
              ],
            }),
      };
    }

    videoCreator.organization = updatedOrganization;
    return updatedOrganization;
  }

  public async save(
    newTitle: string,
    type: 'basic' | 'karaoke' = 'basic',
    setting: 'text-settings' | 'karaoke-settings' | null = null,
    prevConfig: ElementState['source'] | null = null,
  ) {
    const album = videoCreator.organization;
    if (!album) return;
    this.isLoading = true;
    const karaokeConfig = videoCreator.karaokeProducer.getKaraokeConfig();
    const lowerThirdConfig = videoCreator.textProducer.getTextConfig();

    let templateId = (this.modifiedTemplateData?.template?.id ||
      this.selectedTemplateId) as string;

    let config =
      type === 'basic' || setting === 'text-settings'
        ? lowerThirdConfig
        : karaokeConfig;

    if (prevConfig) config = prevConfig;

    const title = newTitle || (this.selectedBrandingTemplate!.title as string);

    const brandingUpdateParams = this.formatBrandingUpdateFields(
      config,
      title,
      type,
    );

    const itemType = await videoCreator.datoClient?.itemTypes.find(
      type === 'basic' ? 'lower_third_text_branding' : 'karaoke_text_branding',
    );

    const aspectRatio =
      videoCreator.currentVideo?.aspectRatio || AspectRatio.AR_16_9;

    const isExistingTemplate =
      this.selectedBrandingTemplate &&
      templateId !== 'default' &&
      templateId !== 'custom';

    if (prevConfig || (this.isTemplateModified && isExistingTemplate)) {
      const updatedBranding = await videoCreator.datoClient?.items.update(
        templateId,
        brandingUpdateParams,
      );
    } else {
      const savedBranding = await videoCreator.datoClient?.items.create({
        item_type: { type: 'item_type', id: itemType!.id },
        ...brandingUpdateParams,
      });
      this.selectedTemplateId = savedBranding!.id;
      templateId = savedBranding!.id;
    }

    const updatedOrganization = this.formatOrganizationBrandingFields(
      config,
      title,
      type,
      templateId,
    );

    const lastUsedTemplates = this.setLastUsedTemplate(
      templateId,
      title,
      type,
      aspectRatio,
    );
    updatedOrganization.lastUsedTemplates = lastUsedTemplates;

    await videoCreator.albumRepository?.update(updatedOrganization);
    await videoCreator.videoRepository?.updateVideo({
      id: videoCreator.currentVideo?.id!,
      extraElementData: {
        ...videoCreator.currentVideo?.extraElementData,
        karaokeConfig: config,
      },
    });

    if (!prevConfig) {
      const element = videoCreator.getActiveElement()?.source || null;
      this.getAllBrandingTemplates(type, aspectRatio, false, false, element);
    } else {
      this.loadAllTemplates(type, aspectRatio);
    }

    this.toggleModifiedTemplateData();
    this.prepareTemplateToSave = false;
    this.isLoading = false;
  }

  private genericConfigToApply(
    template: Record<
      keyof (KaraokeTextBranding | LowerThirdTextBranding),
      string | number | boolean | null
    >,
    defaultSetting: Record<string, any>,
  ) {
    return {
      font_family: (template.fontFamily as string) || defaultSetting.fontFamily,
      font_size: (template.fontSize || defaultSetting.fontSize) as string,
      fill_color: template.fillColor as string,
      background_color: template.backgroundColor as string,

      font_weight: (template.fontWeight || defaultSetting.fontWeight) as string,

      x_alignment: (template.xAlignment || '0%') as string,

      ...(template.uppercase
        ? {
            text_transform: 'uppercase',
          }
        : { text_transform: 'none' }),

      ...(template.italic
        ? {
            font_style: 'italic',
          }
        : {
            font_style: 'normal',
          }),
    };
  }

  public saveToExtraElementData(type: 'karaoke' | 'basic', templateId: string) {
    if (type === 'basic') {
      if (videoCreator.textProducer.config?.id) {
        const existingData = videoCreator.currentVideo?.extraElementData || {};

        videoCreator.currentVideo!.extraElementData = {
          ...existingData,
          lowerThirdTemplates: {
            ...(existingData?.lowerThirdTemplates || {}),
            [videoCreator.textProducer.config.id]: templateId,
          } as ExtraElementData | KaraokeConfig | null | undefined,
        };
      }
    } else {
      videoCreator.currentVideo!.extraElementData = {
        ...(videoCreator.currentVideo?.extraElementData || {}),
        karaokeTemplateId: templateId as
          | ExtraElementData
          | KaraokeConfig
          | null
          | undefined,
      };
    }
  }

  public async applyLowerThirdTemplate(
    template: LowerThirdTemplate,
    source: ElementState['source'] | null = null,
    applySource: boolean = false,
  ) {
    const defaultSetting = this.getBasicTextConfig();

    let config: ElementState['source'] = this.genericConfigToApply(
      template,
      defaultSetting,
    );

    const enterAnimation = template.enterAnimation;
    const exitAnimation = template.exitAnimation;
    const enterAnimationLength = template.enterAnimationLength;
    const exitAnimationLength = template.exitAnimationLength;

    if (enterAnimation && enterAnimation !== 'none') {
      config.animations = [
        ...(config.animations || []),
        {
          duration: enterAnimationLength || 1,
          time: 0,
          type: enterAnimation,
        },
      ];
    }

    if (exitAnimation && exitAnimation !== 'none') {
      config.animations = [
        ...(config.animations || []),
        {
          duration: exitAnimationLength || 1,
          time: 'end',
          reversed: true,
          type: exitAnimation,
        },
      ];
    }

    if (source) {
      config = { ...config, text: source.text };
    }

    const elementId = applySource && source ? source.id : undefined;
    console.log('config', config);

    await videoCreator.textProducer.modifyProperty(config, true, elementId);

    this.saveToExtraElementData('basic', template.id as string);

    return config;
  }

  private async applyKaraokeTemplate(template: KaraokeTemplate) {
    const defaultSetting = this.getKaraokeTextConfig();

    let config: Partial<KaraokeConfig> = this.genericConfigToApply(
      template,
      defaultSetting,
    );

    const karaokeConfig = videoCreator.karaokeProducer.getKaraokeConfig();
    const karaokeAnimation = template.animation;

    if (!karaokeAnimation || karaokeAnimation === 'none') {
      config.animations = [];
    }

    if (
      karaokeAnimation &&
      karaokeAnimation !== 'none' &&
      karaokeAnimation !== 'instagram'
    ) {
      config.animations = [
        {
          background_effect: 'animated',
          duration: karaokeConfig?.animations?.[0]?.duration || 1,
          time: 0,
          type: template.animation,
        },
      ];
    }

    if (karaokeAnimation === 'instagram') {
      config.instagramEffect = true;
      config.language = 'original';
      config.instagramLines = (template.instagramLines ||
        karaokeConfig.instagramLines) as 1 | 2 | 3 | 4 | 5 | 6 | 7;
      config.animations = [
        {
          background_effect: 'animated',
          duration: karaokeConfig?.animations?.[0]?.duration || 1,
          time: 0,
          type: 'text-appear',
        },
      ];
    } else {
      config.instagramEffect = false;
    }

    config.stroke_color = template.strokeColor as string;

    config.stroke_width = (template.strokeWidth ||
      karaokeConfig.stroke_width) as string;

    config.x = template.xPos
      ? `${parseFloat(template.xPos as string)}%`
      : karaokeConfig.x;

    config.y = template.yPos
      ? `${parseFloat(template.yPos as string)}%`
      : karaokeConfig.y;

    config.hideComma = !!template.hideComma;
    config.hidePeriod = !!template.hidePeriod;
    config.hideFillers = !!template.hideFillers;

    await videoCreator.karaokeProducer.rerenderWithNewConfig({
      ...karaokeConfig,
      ...config,
    });

    this.saveToExtraElementData('karaoke', template.id as string);

    return config;
  }

  async applyTemplate(
    template: Template,
    type: 'basic' | 'karaoke',
    source: ElementState['source'] | null = null,
  ) {
    this.selectedBrandingTemplate = template;
    this.selectedTemplateId = template.id as string;
    if (template.id === 'default' || template.id === 'custom') {
      this.toggleModifiedTemplateData();
    }
    if (type === 'basic') {
      await this.applyLowerThirdTemplate(
        template as LowerThirdTemplate,
        source,
      );
    } else {
      await this.applyKaraokeTemplate(template as KaraokeTemplate);
    }
    console.trace('applyTemplate');
    if (template.id !== 'default' && template.id !== this.lastUsedTemplateId) {
      this.setLastUsedTemplate(
        template.id as string,
        template.title as string,
        type,
        videoCreator.currentVideo?.aspectRatio || AspectRatio.AR_16_9,
      );
      await videoCreator.albumRepository?.update(videoCreator.organization!);
    }
  }

  loadDefaultTemplateOnVideoLoad() {
    this.toggleModifiedTemplateData();
    const isTextSettingOpen =
      videoCreator.sidebarOptions === SidebarOption.text;

    const isKaraokeSettingOpen =
      videoCreator.sidebarOptions === SidebarOption.karaoke;

    if (!isKaraokeSettingOpen && !isTextSettingOpen) return;
    const type = isKaraokeSettingOpen ? 'karaoke' : 'basic';
    const activeElement = videoCreator.getActiveElement();
    const hasActiveElement =
      type === 'karaoke'
        ? videoCreator.tracks?.has(KARAOKE_TRACK_NUMBER)
        : activeElement?.source.type === 'text';
    if (!hasActiveElement) {
      videoCreator.sidebarOptions = SidebarOption.media;
      videoCreator.mediaSubMenu = MediaCard.photo;
    }

    this.getAllBrandingTemplates(
      type,
      videoCreator.currentVideo?.aspectRatio || AspectRatio.AR_16_9,
      hasActiveElement,
    );
    if (isTextSettingOpen && activeElement?.source.type === 'text') {
      videoCreator.textProducer.setTextConfig(activeElement.source);
    }
  }

  modifyChangesOnStateChange() {
    const activeElement = videoCreator.getActiveElement();
    const source = activeElement?.source;

    if (source?.track !== KARAOKE_TRACK_NUMBER && source?.type !== 'text')
      return;

    if (!source) return;

    const fieldsToCheck = ['width'];

    const type = source.track === KARAOKE_TRACK_NUMBER ? 'karaoke' : 'basic';

    if (type === 'karaoke') {
      fieldsToCheck.push('x', 'y');
    }

    const config =
      type === 'basic'
        ? videoCreator.textProducer.getTextConfig()
        : videoCreator.karaokeProducer.getKaraokeConfig();

    let hasChanges = false;

    for (let field of fieldsToCheck) {
      if (config[field as keyof KaraokeConfig] !== source[field]) {
        hasChanges = true;
      }
    }
    if (!hasChanges) return;

    if (source.track === KARAOKE_TRACK_NUMBER) {
      videoCreator.karaokeProducer.setConfig({
        ...config,
        ...{ x: source.x || '50%' },
        ...{ y: source.y || '50%' },
        ...{
          width: source.width || DEFAULT_KARAOKE_CONFIG.width,
        },
      });
      videoCreator.refreshKaraokeElements();
    } else if (source.type === 'text') {
      videoCreator.textProducer.setTextConfig(source);
    }

    this.checkPropsChanged(type, source, false, true);
  }
}
